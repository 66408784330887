import auth from '@/actions/authorizations/index.js'

export default class {
  execute(spec, component) {
    const t = TrelloPowerUp.iframe()
    const token = spec.properties.token

    $tpu.r.data.set(t, "member", "private", $tpu.keys.mbrAuthToken(), token).then(() => {
      // Launch a large board to impress first time users.
      auth.requestRelaunch(t, "large")
    })
  }
}
